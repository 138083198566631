<template>
  <metainfo>
    <link rel="canonical" href="https://lk4eiji.dev">
    <template v-slot:title="{ content }">{{ content }} - Lk4eiji</template>
    <template v-slot:description="{ content }">{{ content }}</template>
  </metainfo>
  <router-view/>
  <Footer/> 
</template>

<script>
  import Footer from './components/Footer.vue'
  export default{
    name: "app",
    components:{
      Footer
    },
    metaInfo(){
      return{
        
      }
    }
  }
</script>